.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5%;
  align-items: center;
}
.block {
  background-image: url(../imgs/pozadi.png);
  background-repeat: no-repeat;
  width: 200px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5% 2%;
  margin-left: 3%;
  margin-top: 5%;
}
.block img {
  width: 100%;
}
.path {
  position: absolute;
  top: -100px;
  left: -7%;
  width: 120px;
  z-index: 2;
  height: 100vh;
}
.section {
  position: relative;
}
.block p {
  margin-top: 10%;
  text-align: center;
  margin-bottom: 5%;
}

@media screen and (max-width: 733px) {
  .features {
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 5%;
    align-items: center;
  }
  .block img {
    width: 60%;
  }
  .block {
    background-image: url(../imgs/pozadi.png);
    background-repeat: no-repeat;
    width: 150px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5% 2%;
    margin-left: 3%;
    margin-top: 5%;
  }
}
