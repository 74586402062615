.tile {
  min-width: 250px;
  height: 380px;
  margin: 10px;
  background-color: #3ea8b0;
  background-size: cover;
  position: relative;
  transition: all 0.4s ease-out;
  box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.44);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
}
.animate-text {
  color: white;
}
.sponzor{
  background-color: rgba(0, 0, 0, 0.61);
padding: 0  !important;
margin-top: 1rem;
padding: .2rem .4rem !important;
border-radius: 1rem;

}
.tile:hover {
  height: 500px;
  color: white;
}
.ramecek{
  border: #D41317 3px solid;
}
.tile-cover {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.price-img {
  width: 100%;
  height: 100%;
  top: 0%;
  right: 0%;
  position: absolute;
  z-index: 0;
  transition: all 0.4s ease-out;
}
.tile .text {
  /*   z-index:99; */
  position: absolute;
  padding: 30px;
  height: calc(100% - 60px);
}
.partner-logo {
  display: block;
  width: 150px;
  transform: translateX(-200px);
  transition-delay: 0.2s;
}
.tile h1 {
  font-weight: 100;
  margin: 0;
  font-size: 1.5rem;
  color: #3ea8b0;
  background-color: rgba(255, 255, 255, 0.274);
  transition: all 0.8s ease-out;
}
.tile span {
  color: #D41317;
  background-color: #3ea8b06c;
  font-weight: bold;
  font-size: 1rem;
  padding: 1px 3px;
}
.tile h2 {
  font-weight: 100;
  margin: 20px 0 0 0;
  font-style: italic;
  font-size: 1rem;
  transform: translateX(200px);
}
.tile p {
  font-weight: 300;
  margin: 20px 0 0 0;
  line-height: 25px;
  /*   opacity:0; */
  transform: translateX(-200px);
  transition-delay: 0.2s;
  padding-top: 20%;
}
.animate-text {
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.tile:hover {
  /*   background-color:#99aeff; */
  box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
  transform: scale(1.05);
}
.tile:hover img {
  opacity: 0;
}
.tile:hover .animate-text {
  transform: translateX(0);
  opacity: 1;
}
.tile:hover h1 {
  color: white;
  background-color: transparent;
}

.tile:hover span {
  opacity: 1;
  transform: translateY(0px);
}

@media (max-width: 1000px) {
  .wrap {
    flex-direction: column;
    width: 400px;
  }
}
.tile-bottom {
  position: absolute;
  top: 280px;
}
.podminky {
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 2rem;
}
.podminky a {
  color: white;
}
.zpet {
  margin-top: 1rem !important;
}
.design{
  display: block;
  text-align: center;
  margin-top: .5rem;

}
@media screen and (max-width: 733px) {
  .tile {
    min-width: 250px;
    height: 300px;
    margin: 10px;
    background-color: #3ea8b0;
    background-size: cover;
    position: relative;
    transition: all 0.4s ease-out;
    box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.44);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
  }
}
