.hero {
  background-image: url(../imgs/pozadi_X.png);
  min-height: 70vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  position: relative;
}

.hero {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex-direction: column;
  position: relative;
}
.hero h1 {
  font-weight: bold;
  color: #1eb4b2;
  font-size: 5rem;
  padding-top: 1rem;
}
.popis{
  font-weight: bold;
  color: white;
}
.popis p{
margin: 2rem 0rem;
}
.onas{
  font-weight: bold;
}
.zpet{
  text-align: center !important;
  margin: 0 auto !important;
  color:white;
}
.hero .logo {
  position: absolute;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 30px;
  text-align: center;
}
.button button {
  background: none;
  border: none;
  font-family: "Nunito", sans-serif;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
  color: white;
}
.button {
  background: white;
  padding: 20px 50px;
  flex-shrink: 0;
  transition: all 200ms;
  cursor: pointer;
  margin-top: 2rem;
  height: 100%;
  border-radius: 12rem;
  background-color: #1eb4b2;
  box-shadow: 0 0 50px #fff, 0 0 10px #fff, 0 0 5px #1eb4b2, 0 0 20px #1eb4b2,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
  animation: heart-pulse 2s infinite ease-out;
}

.hero .button:hover {
  transform: scale(1.3);
  box-shadow: 0 0 150px #fff, 0 0 110px #fff, 0 0 5px #1eb4b2, 0 0 120px #1eb4b2,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  border: 3px dashed #e23046;
}
.play {
  color: #e23046;
}
.jerab {
  width: 30%;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 1018px) {
  .hero {
    min-height: 100vh;
    background-size: cover;
    background-position: right;
    overflow: hidden;
  }
}
@media screen and (max-width: 1515px) {
  .hero {
    min-height: 100vh;
    background-size: cover;
    background-position: right;
    overflow: hidden;
  }
  .hero .logo {
    width: 200px;
    margin-bottom: 2rem;
  }
  .hero button {
    margin: 0;
  }
}
@media screen and (max-width: 700px) {
  .hero {
    min-height: 100vh;
    background-size: cover;
    background-position: right;
    overflow: hidden;
  }

  .hero h1 {
    font-size: 3rem;
    font-weight: bold;
  }
  .hero .logo {
    margin-bottom: 6rem;
  }
  .hero {
    background-image: url(../imgs/mobile_bckg.png);
    min-height: 40vh;
    background-size: cover;
    overflow: hidden;
  }
  .hero .button {
    font-size: 0.3rem;
    padding: 10px 30px;
  }
}
