.heroThanks {
    background-image: url(../imgs/pozadi_X.png);
    min-height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    position: relative;
  }
  .heroThanks h1 {
    font-weight: bold;
    color: #1eb4b2;
    font-size: 5rem;
    padding-top: 2rem;
  }