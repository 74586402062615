*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}
html {
  scroll-behavior: smooth;
  height: 100vh;

}
.homeSec{
  position: relative;
}
.onasside{
  position: absolute;
  transform: rotate(-90deg);
right: -220px;
top: 48%;
z-index: 20;
}

.onas h1{
text-align: center;
color: white;
}
body {
  font-family: "Nunito", sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
  background-color: #1EB4B2;
  
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}

h1,
h2,
h3,
span,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

p {
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }

}
@media screen and (max-width: 800px) {
  .onasside{
    position: absolute;
    transform: rotate(-90deg);
  right: -120px;
  top: 50%;
  z-index: 20;
  }

}
@media screen and (max-width: 600px) {
  .onasside{
    position:static;
    transform: rotate(0deg);
  right: 0px;
  top: 0%;
  z-index: 20;
  text-align: center;
  }
  .odkazinfo{
    margin-bottom: 2rem;
  }
  

}
/*  global classes */

/* section */
.section-hero {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  
}
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  height: 100%;
}
