.pic {
  width: 150px;
  height: 30%;
}
.slider {
  padding: 2% 0%;
}
.alice-carousel__stage-item {
  text-align: center;
}
@media screen and (max-width: 670px) {
  .pic {
    width: 100px;
    height: 30%;
  }
  .alice-carousel__wrapper{
    padding: 1rem 0rem;
  }
}
