.challange {
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
  height: 100%;
}
.fa-arrow-left {
  margin-left: 8px;
}
.vypln {
  display: flex;
  align-items: center;
  justify-content: center;
}
.challange h2 {
  font-size: 9rem;
  color: #d41317;
  z-index: 1;
}
.challange h3 {
  font-size: 2rem;
  color: #d41317;
  z-index: 1;
}
.challange h4 {
  color: white;
  font-size: 1rem;
  letter-spacing: 0.3rem;
  margin-top: -1.5rem;
}

.vyhry {
  position: absolute;
  width: 300px;
  z-index: -1;
}
.kolobezka {
  top: 50%;
  left: 0%;
}
.iPad {
  top: 10%;
  left: 10%;
}
.airpods {
  top: 45%;
  right: 0%;
}
.skutr {
  top: 0%;
  right: 10%;
}
.challange button {
  font-family: "Nunito", sans-serif;
  font-size: 2rem;
  margin: 5%;
  border: none;
  background-color: #d41317;
  padding: 1% 3%;
  border-radius: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 100ms;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transform: scale(1);
  animation: heart-pulse 2s infinite ease-out;
}
.challange button:hover {
  transform: scale(1.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}
.vp-center {
  z-index: 3;
}
#videoblock {
  margin: 0;
  z-index: 13;
}
.player {
  z-index: 13;
}
.vp-nudge-shade {
  z-index: 113;
}
.vice {
  width: 350px;
  padding: 1rem 0.5rem;
  margin: 0 auto;
  border-radius: 30px;
  line-height: 2;
  color: white;
  font-weight: bold;
}
.vice {
  background-color: #d41317;
  padding: 0.3rem 0.2rem;
  border-radius: 30px;
  transition: all 1000ms;
}
.odkazinfo {
  color: white !important;
  margin-top: 0.5rem !important;
}
.vice:hover {
  transform: scale(1.1);
  overflow: hidden;
}

@media screen and (max-width: 1250px) {
  .sipka {
    width: 100px;
  }
  .vyhry {
    width: 200px;
  }
  .kolobezka {
    top: 50%;
    left: 0%;
  }
  .skutr {
    top: 10%;
    right: 5%;
  }
  .challange h2 {
    font-size: 7rem;
  }
  .challange h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .path {
    display: none;
  }
  .airpods {
    top: 60%;
    right: 0%;
  }
  .kolobezka {
    top: 60%;
    left: 0%;
  }
  .iPad {
    top: 5%;
    left: 0%;
  }
  .skutr {
    top: 5%;
    right: 0%;
  }
  .challange h2 {
    font-size: 7rem;
  }
  .challange h3 {
    font-size: 1.5rem;
  }
  .challange h4 {
    color: white;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    margin-top: -1.5rem;
  }
  .challange button {
    font-family: "Nunito", sans-serif;
    font-size: 1.5rem;
    margin: 2%;
    border: none;
    background-color: #d41317;
    padding: 1% 3%;
    border-radius: 50px;
  }
}
@media screen and (max-width: 670px) {

  .vyhry {
    width: 80px;
  }
  .kolobezka {
    top: 30%;
    left: 0%;
  }
  .airpods {
    top: 30%;
    right: 0%;
  }
  .iPad {
    top: 30%;
    left: 55%;
  }
  .skutr {
    top: 30%;
    right: 50%;
  }
  .vice {
    width: 100%;
    font-size: 0.5rem !important;
    padding: .8rem 0rem;
  }
  .vice h4 {
    font-size: 0.3rem;
    
  }
  .vice span {
    font-size: 0.8rem;
  }
}
@keyframes heart-pulse {
  70% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.65);
  }
  100% {
    transform: scale(1);
  }
}
